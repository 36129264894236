<template>
    <div>
    <button class="button button--icon" @click="offcanvasShow = true"><svg class="icon" width="22" height="22"><use xlink:href="#edit" /></svg></button>
    <UiOffCanvas :offcanvas="offcanvasShow" :offcanvasShow.sync="offcanvasShow">

        <SuccessAlert/>
        <template v-slot:header>
            <h4>Modifica {{ percorso.titolo }} </h4>
        </template>

        <template v-slot:form>
            <template v-if="!loading">
                <ErrorsAlert />
                <div class="form">
                    <div class="field-group flex">
                        <input type="text" v-model="titolo">
                    </div>


                    <div class="field-group flex">
                        <label>Sottotitolo</label>
                        <input type="text" v-model="sottotitolo">
                    </div>

                    <div class="field-group flex">
                        <label>Obiettivo</label>
                        <textarea v-model="obiettivo"></textarea>
                    </div>

                    <div class="field-group flex">
                        <div class="field col col-9">
                        <label>Categoria</label>
                        <multiselect v-model="categoria"
                        :options="categorie"
                        :searchable="false"
                        :allow-empty="false"
                        :show-labels="true"
                        track-by="value"
                        label="label"
                        placeholder="Scegli categoria"></multiselect>
                        </div>
                        <div class="field col col-3">
                            <label>Ordine</label>
                            <input type="number" v-model="ordine">
                        </div>
                    </div>

                    <div>
                        <label>Testo</label>
                        <vue-editor v-model="testo" :editorToolbar="customToolbar"></vue-editor>
                    </div>

                     <div class="field-group flex">
                        <label>Credits</label>
                        <textarea v-model="credits"></textarea>
                    </div>
                    
                    <div class="form--action">
                        <button class="button--delete" @click.prevent="modalShow = true" :disabled="disableSaveButton">Elimina percorso</button>
                        <button class="button button--small m-l-auto" @click.prevent="aggiornaPercorso(percorso)" :disabled="disableSaveButton">Salva</button>
                    </div>
                </div>
            </template>
            <template v-else>
                <UiPreloader/>
            </template>

        </template>

        <template v-slot:footer>
           
        </template>
     </UiOffCanvas>

     <UiModal :modal="modalShow" :modalShow.sync="modalShow">
            <template v-slot:header>
                <h4 class="modal--title">Sicuri di voler eliminare il percorso {{ percorso.titolo }}?</h4>
            </template>

            <template v-slot:form>
                    <template v-if="!loading">
                        <div class="form">
                            <ErrorsAlert />
                            <p>Digita <strong>ELIMINA</strong> nel campo sottostante per eliminare il percorso <strong>{{ percorso.titolo }}</strong>. <br/>Verranno eliminate tutte le lezioni e quiz associati</p>
                            <div class="field-group flex">
                                <div class="field col col-12">
                                    <input type="text" v-model="confermaEliminazione">
                                </div>
                            </div>
                            <button class="button button--annulla" @click="modalShow = false">Annulla</button>     
                            <button class="button button--conferma" @click.prevent="eliminaPercorso(percorso)" :disabled="confermaEliminazione != 'ELIMINA'">Conferma ed elimina</button>
                        </div>
                    </template>
                    <template v-else>
                        <UiPreloader/>
                    </template>
            </template>
     </UiModal>

    </div>
</template>
<script>

import SuccessAlert from '@/components/SuccessAlert.vue'
import ErrorsAlert from '@/components/ErrorsAlert.vue'
import { VueEditor } from "vue2-editor";

import { mapGetters, mapState, mapActions } from 'vuex'

export default {
    name: 'ModificaIstituto',

    data() {
        return {
            id: '',
            loading: false,
            disableSaveButton: false,
            offcanvasShow: false,
            titolo: this.percorso.titolo,
            sottotitolo: this.percorso.sottotitolo,
            obiettivo: this.percorso.obbiettivo,
            credits: this.percorso.credits,
            ordine: this.percorso.numero,
            testo: this.percorso.testo,
            categorie: [
                { value: 'tipo1', label: 'Prime e seconde scuola primarie' },
                { value: 'tipo2', label: 'Terze, quarte e quinte scuola primarie' },
                { value: 'tipo3', label: 'Prime, seconde e terze scuola primaria di secondo grado' }
             ],
            modalShow: false,
            confermaEliminazione: '',
            customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }]]
        }
    },
    props: {
        percorso: Object
    },

    components: {
        VueEditor,
        ErrorsAlert,
        SuccessAlert
    },

    computed: {
        categoria()  {
            return {
                value: this.percorso.categoria,
                label: this.categorie.find(v => v.value === this.percorso.categoria).label
            }
        },

    },
    methods: {
        ...mapActions('percorsi',[
            'updatePercorso',
            'deletePercorso'
        ]),

        aggiornaPercorso () {

            console.log(this.categoria)
            this.loading = true
            this.disableSaveButton = true
            this.$store.state.success = null
            this.updatePercorso({
                id:  this.percorso.id,
                titolo: this.titolo,
                sottotitolo: this.sottotitolo,
                obbiettivo: this.obiettivo,
                categoria: this.categoria.value,
                testo: this.testo,
                credits: this.credits,
                numero: this.ordine,
            }).then((response) => {
                this.loading = false
                this.offcanvasShow = false
                this.disableSaveButton = false
                this.$store.dispatch('percorsi/fetchPercorsi')
            }).catch(e => {
                this.loading = false
                this.$store.state.success = null
                this.$store.state.errors = e.response.data;
                this.disableSaveButton = false
            })
        },

        eliminaPercorso (percorso) {
            this.loading = true
            this.disableSaveButton = true
            this.deletePercorso(percorso).then((response) => {
                this.offcanvasShow = false
                this.loading = false
                this.disableSaveButton = false
                this.$store.dispatch('percorsi/fetchPercorsi')
            }).catch(error => {
                console.log('Errore:'+error)
            })
        },

    }
}
</script>