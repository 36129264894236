<template>
    <span>
    <button class="button button--small" @click="modalShow = true">Nuovo Percorso</button>
    <UiModal buttonClass="button--small" buttonLabel="Nuovo istituto" :modal="modalShow" :modalShow.sync="modalShow">
                <template v-slot:header>
                    <h4 class="modal--title">Nuovo percorso</h4>
                </template>

                <template v-slot:form>
                    <template v-if="!loading">
                        <ErrorsAlert />

                        <div class="form">
                            <div class="field-group flex">
                                <label>Titolo percorso</label>
                                <input type="text" v-model="titolo" ref="titolo">
                            </div>

                            <div class="field-group flex">
                                <label>Sottotitolo</label>
                                <input type="text" v-model="sottotitolo">
                            </div>

                            <div class="field-group flex">
                                <label>Obiettivo</label>
                                <textarea v-model="obiettivo"></textarea>
                            </div>

                            <div class="field-group flex">
                                <div class="field col col-9">
                                <label>Categoria</label>
                                 <multiselect v-model="categoria"
                                    :options="categorie"
                                    :searchable="false"
                                    :allow-empty="false"
                                    :show-labels="true"
                                    track-by="label"
                                    label="label"
                                    placeholder="Scegli categoria"></multiselect>
                                </div>
                                <div class="field col col-3">
                                    <label>Ordine</label>
                                    <input type="number" v-model="ordine">
                                </div>

                            </div>

                            <div>
                                <label>Testo</label>
                                <vue-editor v-model="testo" :editorToolbar="customToolbar"></vue-editor>
                            </div>

                            <div class="field-group flex">
                                <label>Credits</label>
                                <textarea v-model="credits"></textarea>
                            </div>

                            <div class="form--action">
                                <button class="button button--annulla" @click="modalShow = false">Annulla</button>
                                <button class="button button--conferma" @click.prevent="createPercorso">Salva</button>                    
                            </div>

                
                        </div>

                    </template>
                    <template v-else>
                        <UiPreloader/>
                    </template>              
                </template>

     </UiModal>
    </span>
</template>
<script>

import ErrorsAlert from '@/components/ErrorsAlert.vue'
import { VueEditor } from "vue2-editor";

import { mapActions } from 'vuex'

export default {
    name: 'NuovoPercorso',

    data() {
        return {
            titolo: '',
            sottotitolo: '',
            obiettivo: '',
            credits: '',
            categoria: '',
            categorie: [
                    { value: 'tipo1', label: 'Prime e seconde scuola primarie' },
                    { value: 'tipo2', label: 'Terze, quarte e quinte scuola primarie' },
                    { value: 'tipo3', label: 'Prime, seconde e terze scuola primaria di secondo grado' },
                ],
            testo: '',
            ordine: '',
            loading: false,
            modalShow: false,
            customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }]]
        }
    },

    props: {

    },

    watch: {
    // autofocus sull'input
    modalShow(val){
        if (val == true){
            this.$nextTick(() => {
                this.$refs.titolo.focus()
            })
        }
      }
    },

    components: {
        VueEditor,
        ErrorsAlert
    },

    methods: {
    ...mapActions('percorsi',[
        'addPercorso'
    ]),


    createPercorso () {
      this.loading = true
      this.addPercorso({
          titolo: this.titolo,
          sottotitolo: this.sottotitolo,
          obbiettivo: this.obiettivo,
          credits: this.credits,
          categoria: this.categoria.value,
          testo: this.testo,
          numero: this.ordine,
      }).then((response) => {
            this.titolo = ''
            this.$store.dispatch('percorsi/fetchPercorsi')
             this.loading = false
            this.modalShow = false
      }).catch(e => {
            this.$store.state.success = null;
            this.$store.state.errors = e.response.data;
            this.loading = false
        })
    }

    }
}
</script>