<template>
  <div>
      <header>
        <h1 class="header--title">Percorsi</h1>
        <div class="header--right">
          <NuovoPercorso />
        </div>
      </header>

      <table class="table" v-if="getPercorsi">

            <tr class="table--header">
                <th class="cell">
                  Titolo
                </th>
                <th class="cell">
                  Ordine
                </th>
                <th class="cell">
                  Categoria
                </th>

                <th class="cell">
                  Lezioni
                </th>
          
                <th class="cell">
                  Quiz
                </th>
                
                <th class="cell">
                  Azioni
                </th>
          </tr>

          <tr v-for="percorso in getPercorsi" :key="percorso.id" class="row">

              <td class="cell">
                <strong>
                  <router-link :to="{ name: 'EditorLezioni', params: { percorsoId: percorso.id }}">{{ percorso.titolo }}</router-link></strong>
              </td>

              <td class="cell">
                 {{ percorso.numero }} 
              </td>

              <td class="cell">
                <span class="badge" :class="percorso.categoria"> {{ percorso.categoria }}</span>
              </td>

              <td class="cell">
                <router-link :to="{ name: 'EditorLezioni', params: { percorsoId: percorso.id }}" class="button button--withicon button--extrasmall">
                  <template v-if="percorso.lezioni.filter(lezione => lezione.percorso != null) && percorso.lezioni.filter(lezione => lezione.percorso != null).length > 0">
                    <span class="badge">{{ percorso.lezioni.filter(lezione => lezione.percorso != null).length }}</span> Lezioni
                    <svg class="icon" width="14" height="14"><use xlink:href="#edit" /></svg>
                  </template>
                  <template v-else>
                    <svg class="icon" width="10" height="10"><use xlink:href="#add" /></svg>
                    </template> 
                </router-link>
              </td>

              <td class="cell">
                <template v-if="percorso.test">
                    <router-link :to="{ name: 'EditorQuiz', params: { percorsoId: percorso.id} }">Quiz</router-link>
                </template>
                <template v-else>
                    <router-link :to="{ name: 'EditorQuiz', params: { percorsoId: percorso.id} }">
                      <svg class="icon" width="10" height="10"><use xlink:href="#add" /></svg>
                    </router-link>
                </template>
              </td>

               <td class="cell">
                  <ModificaPercorso :percorso="percorso" />

                  <UiOffCanvas buttonLabel="Modifica percorso" buttonClass="button button--neutral button--extrasmall">
                      <template v-slot:header>
                            <h4>Modifica {{ percorso.titolo }} </h4>
                      </template>

                      <template v-slot:content>

                        <form class="form">

                          <div class="field-group flex">
                            <div class="field col col-12">
                              <label>Titolo</label>
                              <input type="text" name="titolo" v-model="percorso.titolo">
                            </div>
                          </div>

                          <div class="field-group flex">
                            <div class="field col col-12">
                              <label>Sottotitolo</label>
                              <input type="text" name="sottotitolo" v-model="percorso.sottotitolo">
                            </div>
                          </div>

                          <div class="field-group flex">
                            <div class="field col col-sm-6">
                            <label>Ordine</label>
                            <input type="text" name="sottotitolo" v-model="percorso.numero">
                            </div>
                            <div class="field col col-sm-6">
                              <label>Categoria
                                <select name="categoria">
                                  <option value="tipo1">Tipo 1</option>
                                  <option value="tipo2">Tipo 2</option>
                                  <option value="tipo3">Tipo 3</option>
                                </select>
                                </label>
                            </div>
                          </div>

                          <div class="field-group flex">
                              <div class="field col col-12">
                              <label>Obiettivo</label>
                                <textarea type="text" name="sottotitolo" v-model="percorso.obbiettivo"></textarea>
                              </div>
                          </div>

                          <div class="field-group flex">
                            <div class="field col col-12">
                                <label>Testo</label>
                                <vue-editor name="testo" v-model="percorso.testo" />
                            </div>
                          </div>


                        </form>

                      </template>

                      <template v-slot:footer>
                        <button class="button m-r-auto" @click="modal = false">Salva</button>
                    </template>

                    </UiOffCanvas>
               </td>

          </tr>

      </table>
      <template v-else>
            <UiPreloader/>
      </template>
  </div>
</template>

<script>
// @ is an alias to /src
import NuovoPercorso from '@/components/editor/NuovoPercorso.vue'
import ModificaPercorso from '@/components/editor/ModificaPercorso.vue'
import { mapGetters, mapState, mapActions } from 'vuex'
export default {
  name: 'EditorPercorsi',

  data: () => {
    return {
     //classi: []
    }
  },
  
  components: {
    NuovoPercorso,
    ModificaPercorso
  },

  computed: {
    ...mapGetters([
      'isLogged',
      'isAdmin',
      'isEducatore',
      'isInsegnante',
      'isClasse',
      'user'
    ]),

    ...mapGetters('percorsi',[
        'getPercorsi'
     ]),

  },

  methods: {
    ...mapActions('percorsi',[
        'fetchPercorsi',
    ]),
  },

  beforeCreate() {
    this.$nextTick().then(() => document.body.className = '')
  },

  beforeMount() {
    this.$store.dispatch('percorsi/fetchPercorsi')
  },

  mounted() {

  }

}
</script>
